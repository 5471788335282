import Exam from '../../models/exam';
import ExamFromResponse from '../../models/from-api-response/exam';
import convertToQuestion from './convert-question';

const convertToExam = (exam: ExamFromResponse): Exam => {
  return {
    id: exam.exam_id,
    title: exam.title,
    instructions: exam.instructions,
    attempts_interval: exam.attempts_interval,
    amount:
      exam.amount_questions && exam.amount_questions.length
        ? exam.amount_questions[0].amount
        : 0,
    points:
      exam.amount_questions && exam.amount_questions.length
        ? exam.amount_questions[0].points
        : 0,
    enabled: !!exam.enabled,
    questions:
      exam.questions && exam.questions.length
        ? exam.questions.map(convertToQuestion)
        : [],
    attempts: exam.attempts,
    average: exam.average || 0,
    info: exam.info || {
      enable_quiz: false,
    },
    examAtStart: exam.info && exam.info.enable_quiz,
  };
};

export default convertToExam;
